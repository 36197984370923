<template>
  <div>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.56514 0.834327C7.41512 0.68435 7.21168 0.600098 6.99954 0.600098C6.78741 0.600098 6.58397 0.68435 6.43394 0.834327L0.833944 6.43433C0.688218 6.58521 0.607583 6.78729 0.609405 6.99705C0.611228 7.2068 0.695363 7.40745 0.84369 7.55578C0.992016 7.70411 1.19267 7.78824 1.40242 7.79007C1.61218 7.79189 1.81426 7.71125 1.96514 7.56553L2.19954 7.33113V12.5999C2.19954 12.8121 2.28383 13.0156 2.43386 13.1656C2.58389 13.3156 2.78737 13.3999 2.99954 13.3999H4.59954C4.81172 13.3999 5.0152 13.3156 5.16523 13.1656C5.31526 13.0156 5.39954 12.8121 5.39954 12.5999V10.9999C5.39954 10.7878 5.48383 10.5843 5.63386 10.4342C5.78389 10.2842 5.98737 10.1999 6.19954 10.1999H7.79954C8.01172 10.1999 8.2152 10.2842 8.36523 10.4342C8.51526 10.5843 8.59954 10.7878 8.59954 10.9999V12.5999C8.59954 12.8121 8.68383 13.0156 8.83386 13.1656C8.98389 13.3156 9.18737 13.3999 9.39954 13.3999H10.9995C11.2117 13.3999 11.4152 13.3156 11.5652 13.1656C11.7153 13.0156 11.7995 12.8121 11.7995 12.5999V7.33113L12.0339 7.56553C12.1848 7.71125 12.3869 7.79189 12.5967 7.79007C12.8064 7.78824 13.0071 7.70411 13.1554 7.55578C13.3037 7.40745 13.3879 7.2068 13.3897 6.99705C13.3915 6.78729 13.3109 6.58521 13.1651 6.43433L7.56514 0.834327Z" fill="currentColor"/>
    </svg>
  </div>
</template>

<script>
import { Vue } from "vue-class-component";

export default class HomeType extends Vue {}
</script>
